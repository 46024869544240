import * as React from "react"
import styled from "styled-components"

const PageTitle = ({ title }) => {
  return <Title>{title}</Title>
}

export default PageTitle

const Title = styled.h1`
  color: ${({ theme }) => theme.primaryLight};

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    margin-left: 30%;
  }

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    display: none;
  }
`
