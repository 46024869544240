import * as React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"

const Button = props => {
  return <StyledButton onClick={() => navigate(-1)}>{props.text}</StyledButton>
}

export default Button

const StyledButton = styled.button`
  width: 5rem;
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  text-align: center;
  border: 0;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.accent};
  color: ${({ theme }) => theme.primaryLight};
  font-size: 1.1rem;
  font-family: "Agenda", sans-serif;
  font-weight: 600;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 0.3);
  cursor: pointer;
  transition: background 0.2s ease-in;

  &:hover {
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryLight};
  }

  &:active {
    box-shadow: none;
    background-color: ${({ theme }) => theme.primaryDark};
    color: ${({ theme }) => theme.primaryHover};
    transform: translateY(1px);
  }
`
