import * as React from "react"
import styled from "styled-components"
import Wave from "../components/Wave"

const PageHeader = props => {
  return (
    <Header dark={props.dark}>
      {props.children}
      <Wave />
    </Header>
  )
}

export default PageHeader

const Header = styled.div`
  position: relative;
  text-align: center;
  padding: 7.5rem 0 4rem 0;
  color: ${props =>
    props.dark
      ? ({ theme }) => theme.primaryDark
      : ({ theme }) => theme.primaryLight};

  /* Tablet and up */
  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    padding-top: 7rem;
    height: 14rem;
  }
`
