import * as React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Layout from "../components/Layout"

const LayoutBlog = ({ title, children }) => {
  const { mobileImage, tabletImage, desktopImage } = useStaticQuery(graphql`
    query BlogImage {
      mobileImage: sanitySingleImage(Id: { eq: "bg-blog-mobile" }) {
        theImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
      tabletImage: sanitySingleImage(Id: { eq: "bg-blog-tablet" }) {
        theImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
      desktopImage: sanitySingleImage(Id: { eq: "bg-blog-desktop" }) {
        theImage {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title={title} />
      <MobileBackgroundImage
        image={mobileImage.theImage.asset.gatsbyImageData}
        alt="Underwater cave"
      />
      <TabletBackgroundImage
        image={tabletImage.theImage.asset.gatsbyImageData}
        alt="Underwater cave"
      />
      <DesktopBackgroundImage
        image={desktopImage.theImage.asset.gatsbyImageData}
        alt="Underwater cave"
      />
      {children}
    </Layout>
  )
}

export default LayoutBlog

const MobileBackgroundImage = styled(GatsbyImage)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
`

const TabletBackgroundImage = styled(GatsbyImage)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: none;
  filter: brightness(120%);

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    display: block;
  }

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    display: none;
  }
`

const DesktopBackgroundImage = styled(GatsbyImage)`
  position: fixed;
  top: 0;
  left: 0;
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    display: block;
  }
`
