import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import LayoutBlog from "../components/LayoutBlog"
import Container from "../components/Container"
import PageHeader from "../components/PageHeader"
import PageTitle from "../components/PageTitle"
import PageContent from "../components/PageContent"
import Button from "../components/Button"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const Post = ({ data }) => {
  const post = data.sanityPost

  return (
    <LayoutBlog title={post.title}>
      <PageHeader>
        <PageTitle title="Blog" />
      </PageHeader>
      <PageContent>
        <Container>
          {post.mainImage != null ? (
            <MainImage
              image={post.mainImage.asset.gatsbyImageData}
              alt={post.title}
            />
          ) : (
            <Placeholder />
          )}
          <PostTitle>{post.title}</PostTitle>
          <PostDate>{post.publishedAt}</PostDate>
          <BlockContent blocks={post._rawBody} serializers={serializers} />
          <Button text="Back" />
        </Container>
      </PageContent>
    </LayoutBlog>
  )
}

export default Post

const MainImage = styled(GatsbyImage)`
  min-height: 250px;
  max-height: 60vh;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 4px;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
  }
`

const Placeholder = styled.div`
  height: 50px;
  width: 90%;
`

const PostTitle = styled.h3`
  font-size: 1.25rem;
  color: ${({ theme }) => theme.primaryDark};

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    font-size: 1.75rem;
  }
`

const PostDate = styled.h4`
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    font-size: 1rem;
    margin-top: 0.25rem;
  }
`

export const query = graphql`
  query Post($slug: String) {
    sanityPost(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      title
      _rawBody(resolveReferences: { maxDepth: 1 })
      mainImage {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
      publishedAt(formatString: "D MMM YYYY")
    }
  }
`
